import { ProductName, IPlanObject, IProductObject } from "@/api";
import { ConfirmPlanModal, ConfirmCancelModal } from "@/components";
import { useModalDispatch } from "@/providers";
import { Button, ModalActionType } from "@narrative-software/narrative-web-ui";

type CancelOptionsModalProps = {
  plan: IPlanObject;
  product: IProductObject;
  parkingPlanID: string;
};

const CancelOptionsModal = ({
  product,
  plan,
  parkingPlanID,
}: CancelOptionsModalProps) => {
  const modalDispatch = useModalDispatch();

  const productName = product.attributes.name;
  const planName = plan.attributes.name.replace("Select", "Narrative"); //TODO: Remove when data has been appropriately replaced in services

  // Text map
  const textMap = {
    [ProductName.Select]: {
      description:
        "Did you know that you can continue to use the free version of Narrative? If you change your mind, it’s super easy to move back onto the Pro Plan later.",
      change: "Move to the free plan",
      cancel: "No thanks, I want to cancel my Narrative subscription.",
    },
    [ProductName.Publish]: {
      description:
        "Did you know that you can keep all of your Publish posts live for just $10 USD per year? And, it’s super easy to reactivate if you want to add more posts in the future.",
      change: "Change to the parking plan",
      cancel:
        "No thanks, I want to cancel my Publish subscription and delete all my Publish posts.",
    },
    [ProductName.Save]: {
      description: "",
      change: "",
      cancel: "",
    },
  };

  // Handle change click
  const handleChangeClick = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: <ConfirmPlanModal {...{ product, plan, parkingPlanID }} />,
      },
    });
  };

  // Handle cancel click
  const handleCancelClick = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: <ConfirmCancelModal {...{ product, plan, parkingPlanID }} />,
      },
    });
  };

  return (
    <>
      <h3 className="text-h3 font-semibold leading-tight">
        Thinking of cancelling {planName}?
      </h3>
      <p className="mt-6">{textMap[productName].description}</p>
      <div className="mt-8">
        <Button colour="black" onClick={handleChangeClick}>
          {textMap[productName].change}
        </Button>
      </div>
      <div className="mt-8 text-center">
        <button
          type="button"
          className="underline hover:no-underline"
          onClick={handleCancelClick}
        >
          {textMap[productName].cancel}
        </button>
      </div>
    </>
  );
};

export default CancelOptionsModal;
