import { RewardType } from "@/api";
import { IS_PRODUCTION } from "@/config";
import { SocialLinks } from "@/containers";
import { publishEvent, TOPICS } from "@/events";
import { useAlertDispatch } from "@/providers";
import CopyIcon from "@/svg/copy.svg";
import { Product } from "@/types";
import { capitalize } from "@/utils";
import { Button, AlertActionType } from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import Image from "next/image";

const getUrlToShare = (type: RewardType, code: string, path?: Product) => {
  let url = IS_PRODUCTION
    ? "https://narrative.so"
    : "https://staging-narrative-web-marketing.netlify.app";
  if (path) {
    url += `/${path}`;
  }
  return `${url}?${type}=${code}`;
};

type Props = {
  type: RewardType;
  code: string;
  product?: Product;
  titleClassName?: string;
};

const ShareLink: React.FC<Props> = ({
  type,
  code,
  product,
  titleClassName,
}) => {
  const alertDispatch = useAlertDispatch();
  const urlToShare = getUrlToShare(type, code, product);

  const onCopyLinkClick = async () => {
    const clipboard = window.navigator.clipboard;
    if (clipboard) {
      await clipboard.writeText(urlToShare);
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "success",
          title: `${capitalize(type)} link copied to clipboard`,
        },
      });

      publishEvent(TOPICS.referralShare, {
        action: "click",
        product: product,
        type: type,
        referral_code: code,
        invite_option_interacted: "copy_link",
      });
    }
  };

  return (
    <article>
      <h4
        className={classNames(
          "text-lg font-semibold text-center mb-4 md:text-left",
          titleClassName
        )}
      >
        Share your {product ? capitalize(product) : ""} {type} link
      </h4>
      <div className="flex flex-col items-center md:flex-row">
        <div className="flex">
          <div className="hidden items-center px-5 border-t border-b border-l border-gray-200 text-gumboot bg-white leading-tight sm:flex md:px-6">
            {urlToShare.replace("https://", "")}
          </div>
          <div className="flex-shrink-0">
            <Button colour="black" onClick={onCopyLinkClick}>
              <Image src={CopyIcon} alt="" unoptimized priority />{" "}
              <span className="ml-2">Copy link</span>
            </Button>
          </div>
        </div>
        <SocialLinks
          urlToShare={urlToShare}
          code={code}
          type={type}
          product={product}
        />
      </div>
    </article>
  );
};

export default ShareLink;
