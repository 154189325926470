import {
  usePreviewChargeMutation,
  ProductInterval,
  ProductName,
  IErrorObject,
  ErrorCode,
  IGoogleOAuthResponse,
} from "@/api";
import {
  PasswordField,
  CouponField,
  OperatingSystemField,
  Terms,
  SelectField,
  GoogleButton,
  Or,
} from "@/components";
import { IS_GOOGLE_AUTH_ENABLED } from "@/config";
import {
  genreFieldOptions,
  blogFieldOptions,
  ISignUpFormTrial,
  signupSourcesFieldOptions,
} from "@/containers";
import { publishEvent, TOPICS } from "@/events";
import { useCoupon, useGoogleSignUp, useSignUp } from "@/hooks";
import {
  browserDetection,
  productLowerCase as productToLowerCase,
} from "@/utils";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Button } from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import { Formik, Form, FormikHelpers, FormikProps } from "formik";
import { useCallback, useRef } from "react";
import * as Yup from "yup";

const { isMacOS } = browserDetection;

export interface ISignUpFormCharge extends ISignUpFormTrial {
  coupon: string;
}

type Props = {
  planID: string;
  interval: ProductInterval;
  product: ProductName;
};

const SignUpFormCharge: React.FC<Props> = ({ planID, interval, product }) => {
  const formRef = useRef<FormikProps<ISignUpFormCharge>>(null);
  const isEmailTaken = useRef(false);
  const [doSignUp] = useSignUp();
  const { isCouponQueryParam, coupon = "" } = useCoupon();

  const onGoogleSuccess = (data: IGoogleOAuthResponse) => {
    const formValues = formRef.current?.values!;
    doSignUp({ planID, interval, product, googleData: data, formValues });
  };

  const {
    openWindow: openGoogleWindow,
    data: googleData,
    isLoading: isGoogleLoading,
  } = useGoogleSignUp(onGoogleSuccess);

  const isGoogleSignUp = !!googleData;
  const isPublish = product === "Publish";
  const productLowerCase = productToLowerCase(product);

  // Initial values
  const initialValues: ISignUpFormCharge = {
    coupon,
    firstName: "",
    email: "",
    password: "",
    genre: "",
    genreOther: "",
    hasBlog: "",
    userOSAnswer: "",
    signupSources: [],
    signupSourceOther: "",
  };

  // Validation
  const validationSchema = Yup.object().shape({
    email: isGoogleSignUp
      ? Yup.string().optional()
      : Yup.string().email("Invalid email").required("Email required"),
    firstName: Yup.string().required("First name required"),
    password: isGoogleSignUp
      ? Yup.string().optional()
      : Yup.string()
          .min(8, "Password must be at least 8 characters")
          .max(100, "Password must be no more than 100 characters")
          .required("Password required"),
    genre: Yup.string().required("Photography genre required"),
    genreOther: Yup.string().optional(),
    hasBlog: isPublish
      ? Yup.string().required("Field required")
      : Yup.string().optional(),
    userOSAnswer: isMacOS
      ? Yup.string().optional()
      : Yup.string().required("Computer type required"),
    signupSources: Yup.array()
      .min(1, "At least one signup source is required")
      .required("Signup source required"),
    coupon: Yup.string().optional(),
  });

  // Preview charge
  const {
    mutateAsync: doPreviewCharge,
    data: chargeData,
    isLoading: isLoadingCharge,
    reset: resetCharge,
  } = usePreviewChargeMutation();
  const discount = chargeData?.data.attributes.discount;
  const isCouponApplied = Boolean(
    discount?.["amount-off"] || discount?.["percent-off"]
  );

  // After a coupon is successfully fetched, apply it to the preview charge
  const onCouponSuccess = async (coupon: string) => {
    try {
      await doPreviewCharge({ planID, interval, coupon });
    } catch {}
  };

  // Handle submit
  const handleSubmit = async (
    values: ISignUpFormCharge,
    actions: FormikHelpers<ISignUpFormCharge>
  ) => {
    try {
      await doSignUp({ planID, interval, product, formValues: values });
    } catch (e: any) {
      const errorObj = e.response?.data?.errors?.[0] as IErrorObject;
      const code = errorObj?.code;
      // If email is taken, activate error state on field
      if (code === ErrorCode.UserExisting) {
        isEmailTaken.current = true;
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={isCouponQueryParam}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        validateForm,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        dirty,
      }) => {
        const showGenreOther = values.genre === "Other";
        const showSignupSourceOther = values.signupSources.includes("Other");

        // If email is taken, activate error state on field
        if (isEmailTaken.current) {
          errors.email = "Email has already been taken";
          isEmailTaken.current = false;
        }

        const setCouponValue = (value: string) =>
          setFieldValue("coupon", value);

        const onGoogleClick = async () => {
          const errors = await validateForm();
          const fields = ["genre", "hasBlog", "userOSAnswer", "signupSources"];
          const errorFields = Object.keys(errors).filter((field) =>
            fields.includes(field)
          );
          if (errorFields.length) {
            errorFields.forEach((field) => setFieldTouched(field, true, true));
          } else {
            openGoogleWindow(productLowerCase);
          }
        };

        const getFieldProps = (
          name: keyof ISignUpFormCharge,
          label: string,
          helperText?: string
        ) => {
          const id = `sign-up-charge-${name}-input`;
          return {
            id,
            name,
            label,
            type: "text",
            value: values[name],
            error: touched[name] && !!errors[name],
            helperText: (touched[name] && errors[name]) || helperText,
            onChange: handleChange,
            onBlur: handleBlur,
            fullWidth: true,
            inputProps: { "data-testid": id },
          };
        };

        console.log(IS_GOOGLE_AUTH_ENABLED);

        return (
          <Form noValidate>
            <div className="mb-5">
              <TextField {...getFieldProps("email", "Email")} type="email" />
            </div>
            <div className="mb-5">
              <TextField
                {...getFieldProps("firstName", "First name")}
                type="text"
              />
            </div>
            <div className="mt-5">
              <PasswordField
                {...getFieldProps(
                  "password",
                  "Password",
                  "Password must be at least 8 characters"
                )}
                showVisibilityToggle
              />
            </div>

            <div className={classNames("mt-5")}>
              <SelectField
                {...getFieldProps(
                  "genre",
                  product === "Select"
                    ? "What is your primary professional shoot type?"
                    : "What is your primary professional post type?"
                )}
                options={genreFieldOptions}
                onChange={(e) => {
                  handleChange(e);
                  publishEvent(TOPICS.signUpGenre, {
                    genre: e.target.value,
                    app: productLowerCase,
                  });
                }}
              />
            </div>

            {showGenreOther && (
              <div className={classNames("mt-5")}>
                <TextField
                  {...getFieldProps(
                    "genreOther",
                    product === "Select"
                      ? "Describe your ‘other’ type of shoot (optional)"
                      : "Describe your ‘other’ type of post (optional)"
                  )}
                  fullWidth
                />
              </div>
            )}

            <div className="mt-7">
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="sign-up-charge-signupSources-input"
                  error={Boolean(touched.signupSources && errors.signupSources)}
                >
                  Where did you hear about Narrative?
                </InputLabel>
                <Select
                  multiple
                  fullWidth
                  name="signupSources"
                  inputProps={{
                    id: "sign-up-charge-signupSources-input",
                    style: { zIndex: -1 }, // ensures the select is clickable in every scenario.
                  }}
                  error={Boolean(touched.signupSources && errors.signupSources)}
                  value={values.signupSources}
                  onChange={(e) => {
                    handleChange(e);
                    publishEvent(TOPICS.signUpSource, {
                      personal_sign_up_source: e.target.value,
                      app: productLowerCase,
                    });
                  }}
                  renderValue={(selected: any) => (
                    <span style={{ textOverflow: "ellipsis" }}>
                      {selected.join(", ")}
                    </span>
                  )}
                  MenuProps={{
                    getContentAnchorEl: null, // prevents auto-adjustment of menu position
                    PaperProps: { style: { marginTop: 40 } },
                  }}
                  data-testid="sign-up-charge-signupSources-input"
                >
                  {signupSourcesFieldOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      <Checkbox
                        color="primary"
                        checked={Boolean(
                          values &&
                            values.signupSources &&
                            values.signupSources.indexOf(value) > -1
                        )}
                      />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
                {touched.signupSources && Boolean(errors.signupSources) && (
                  <FormHelperText error>{errors.signupSources}</FormHelperText>
                )}
              </FormControl>
            </div>

            {showSignupSourceOther && (
              <div className={classNames("mt-5")}>
                <TextField
                  {...getFieldProps(
                    "signupSourceOther",
                    "Describe the 'other' place (optional)"
                  )}
                  fullWidth
                />
              </div>
            )}

            {isPublish && (
              <div className={classNames("mt-5")}>
                <SelectField
                  {...getFieldProps(
                    "hasBlog",
                    "Do you currently have a website?"
                  )}
                  options={blogFieldOptions}
                />
              </div>
            )}

            <div className={classNames("mt-5", { hidden: isMacOS })}>
              <OperatingSystemField
                {...getFieldProps(
                  "userOSAnswer",
                  "What computer(s) do you use?"
                )}
                onChange={(e) => {
                  handleChange(e);
                  publishEvent(TOPICS.signUpOs, {
                    os: e.target.value,
                    app: productLowerCase,
                  });
                }}
              />
            </div>

            <div className="mt-5">
              <CouponField
                name="coupon"
                value={values.coupon}
                planID={planID}
                interval={interval}
                setValue={setCouponValue}
                isLoading={isLoadingCharge}
                isSuccess={isCouponApplied}
                onSuccess={onCouponSuccess}
                resetCharge={resetCharge}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ "data-testid": "sign-up-charge-coupon-input" }}
                fullWidth
              />
            </div>

            <div className="mt-7">
              <Terms className="text-left" />
            </div>

            <div className="mt-7">
              <Button
                type="submit"
                colour="black"
                disabled={!dirty}
                isLoading={isSubmitting}
                showLoader
                testId="sign-up-charge-submit-button"
              >
                Create account
              </Button>

              {IS_GOOGLE_AUTH_ENABLED && (
                <>
                  <Or backgroundClassName="bg-mankey" />
                  <GoogleButton
                    showLoader
                    isLoading={isGoogleLoading}
                    onClick={onGoogleClick}
                  >
                    Continue with Google
                  </GoogleButton>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignUpFormCharge;
