import { RewardType } from "@/api";
import { InviteModal } from "@/containers";
import { publishEvent, TOPICS } from "@/events";
import { useModalDispatch } from "@/providers";
import { Product } from "@/types";
import { Button, ModalActionType } from "@narrative-software/narrative-web-ui";
import { type } from "os";

const getProductTitle = ({
  isSubscribedToSelect,
  isSubscribedToPublish,
}: {
  isSubscribedToSelect: boolean;
  isSubscribedToPublish: boolean;
}) => {
  if (isSubscribedToSelect && isSubscribedToPublish)
    return "Narrative and Publish";
  if (isSubscribedToSelect) return "Narrative";
  if (isSubscribedToPublish) return "Publish";
  return "";
};

type Props = {
  code: string;
  email: string;
  type: RewardType;
  product?: Product;
  isSubscribedToSelect: boolean;
  isSubscribedToPublish: boolean;
};

const Invite: React.FC<Props> = ({
  code,
  email,
  type,
  product,
  isSubscribedToSelect,
  isSubscribedToPublish,
}) => {
  const modalDispatch = useModalDispatch();
  const productTitle = getProductTitle({
    isSubscribedToSelect,
    isSubscribedToPublish,
  })

  const openInviteModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <InviteModal
            {...{
              productTitle,
              code,
              email,
              isSubscribedToSelect,
              isSubscribedToPublish,
            }}
          />
        ),
      },
    });

    publishEvent(TOPICS.referralShare, {
      action: "click",
      product: product,
      type: type,
      referral_code: code,
      invite_option_interacted: "narrative_invite_friends",
    });
  };

  return (
    <article className="mt-10 lg:mt-14">
      <h4 className="text-lg font-semibold mb-3">Invite your friends</h4>
      <p className="mb-4 text-14 lg:text-15">
        Let us email an invite to your friends to try {productTitle}{" "}
        from you.
      </p>
      <Button colour="black" onClick={openInviteModal} className="max-w-60">
        Invite friends
      </Button>
    </article>
  );
};

export default Invite;
