import { UserOSAnswer } from "@/components";
import { TrackingHelper } from "@/helpers";
import { Product } from "@/types";
import { isIPadOS } from "@/utils";
import Bowser from "bowser";
import { Maybe } from "yup/lib/types";

const MINIMUM_SUPPORTED_OS_VERSION = 10.13; // Minimum support macOS version (for both Select and Publish)
const PUBLISH_SUPPORTED_OS_ANSWERS: UserOSAnswer[] = [
  "mac_and_windows",
  "mac",
  "both",
];
const SELECT_SUPPORTED_OS = ["macos", "windows"];
const PUBLISH_SUPPORTED_OS = ["macos"];
// TODO: Going straight to the S3 URL to avoid cloudfront caching for now
const SELECT_WINDOWS_LATEST_S3_URL =
  "https://s3.us-west-2.amazonaws.com/updates.narrative.so/select-latest-windows.txt";

const PRODUCT_DOWNLOAD_LINKS = {
  select_macos: {
    HREF: "https://updates.narrative.so/select-latest.html",
    LABEL: "Download Narrative",
  },
  select_windows: {
    // Set dynamically in resolveDownloadLinks
    HREF: "",
    LABEL: "Download Narrative",
  },
  publish_macos: {
    HREF: "https://updates.narrative.so/narrative-publish-installer-latest.html",
    LABEL: "Download Publish",
  },
  // Publish isn't available for Windows yet, but they can force a download in the UI so for a consistent object we add this entry
  publish_windows: {
    HREF: "https://updates.narrative.so/narrative-publish-installer-latest.html",
    LABEL: "Download Publish",
  },
};

type DownloadLinks = typeof PRODUCT_DOWNLOAD_LINKS;

/**
 * @function resolveDownloadLinks - The latest Windows download link lives in a text file on S3. This function retrieves that and merges it with the more static links.
 * @returns {Promise<DownloadLinks>} - The resolved download links
 */
const resolveDownloadLinks = async () => {
  const response = await fetch(SELECT_WINDOWS_LATEST_S3_URL);
  const windowsLink = await response.text();

  PRODUCT_DOWNLOAD_LINKS.select_windows.HREF = windowsLink;

  return PRODUCT_DOWNLOAD_LINKS;
};

function getProductDownloadUrl(
  product: Product,
  osName: string,
  downloadLinks: DownloadLinks
) {
  const key = `${product}_${osName}` as keyof DownloadLinks;
  return downloadLinks[key];
}

type UserOSStatus =
  | "supported"
  | "unsupported"
  | "mobile"
  | "supported_other_device"
  | "unsupported_version";

function getUserOSStatus({
  product,
  deviceData,
  userOSAnswer,
}: {
  product: Product;
  deviceData: ReturnType<typeof getDeviceData>;
  userOSAnswer: Maybe<UserOSAnswer>;
}) {
  const { isMobile, isUnsupportedMacOSVersion, osName } = deviceData;
  const isSupportedOS = getSupportedOSForProduct(product, osName);
  const isSupportedOSForUserAnswer = getIsSupportedOSForProductFromUserAnswer(
    product,
    userOSAnswer
  );

  if (isSupportedOS) return "supported";
  if (isMobile && isSupportedOSForUserAnswer) return "mobile";
  if (!isSupportedOS && isSupportedOSForUserAnswer)
    return "supported_other_device";
  if (isUnsupportedMacOSVersion) return "unsupported_version";

  return "unsupported";
}

function getIsSupportedOSForProductFromUserAnswer(
  product: Product,
  userOSAnswer: Maybe<UserOSAnswer>
) {
  // We no longer ask users the OS question for Select as it's on Windows and Mac
  // If there is no user answer, the user was on Select or a supported OS
  if (!userOSAnswer) return true;

  return PUBLISH_SUPPORTED_OS_ANSWERS.includes(userOSAnswer);
}

function getSupportedOSForProduct(product: Product, osName: string) {
  return (
    product === "select" ? SELECT_SUPPORTED_OS : PUBLISH_SUPPORTED_OS
  ).includes(osName);
}

function getDeviceData() {
  const parser = Bowser.getParser(window.navigator.userAgent);

  const osName = parser.getOSName(true);
  const isMacOS = osName === "macos" && !isIPadOS();
  const isWindows = osName === "windows";
  const isMobile =
    parser.getPlatformType(true) === "mobile" ||
    parser.getPlatformType(true) === "tablet" ||
    isIPadOS();
  const osVersion = parseFloat(parser.getOSVersion()) || null;
  const browserType = parser.getBrowserName(true).replace(/ /g, "_");
  const isUnsupportedMacOSVersion =
    isMacOS && osVersion && osVersion < MINIMUM_SUPPORTED_OS_VERSION;

  return {
    osName,
    isMacOS,
    isWindows,
    isMobile,
    osVersion,
    isUnsupportedMacOSVersion,
    browserType,
  };
}

function sendTrackingEventsForProduct({
  product,
  deviceData,
  userOSStatus,
}: {
  product: Product;
  deviceData: ReturnType<typeof getDeviceData>;
  userOSStatus: UserOSStatus;
}) {
  const { isMobile } = deviceData;
  // Event for using a supported OS to download
  const sendDesktopDownloadEvent = userOSStatus === "supported";
  // Mobile download page event:
  // 1. For Select we send if they were on mobile
  // 2. For Publish we send if they were on mobile but answered during sign up that they have a mac
  const sendMobileDownloadEvent =
    product === "select"
      ? isMobile
      : isMobile && userOSStatus === "supported_other_device";

  // Event for using a supported OS
  if (sendDesktopDownloadEvent) {
    TrackingHelper.tiktokPixelDownloadEvent(product);
    TrackingHelper.linkedinInsightDownloadEvent(6917436, product);
  }

  if (sendMobileDownloadEvent) {
    TrackingHelper.tiktokPixelDownloadEvent(product);
    TrackingHelper.linkedinInsightDownloadEvent(6917452, product);
  }
}

const sendDownloadPageViewEvent = ({
  isMacOS,
}: ReturnType<typeof getDeviceData>) => {
  TrackingHelper.googleAnalyticsTrackCustom("download_success", {
    macos: isMacOS,
  });
  TrackingHelper.facebookPixelTrackCustom("download_success", {
    macos: isMacOS.toString(),
  });
};

export type { UserOSStatus, DownloadLinks };

export {
  getDeviceData,
  getProductDownloadUrl,
  getUserOSStatus,
  resolveDownloadLinks,
  sendTrackingEventsForProduct,
  sendDownloadPageViewEvent,
};
