import { PlanCard } from "../PlanCard";
import {
  getIsProductCancelled,
  mergeCMSPlanWithStripePlan,
} from "../SelectPlanComparison/utils";
import { SelectPlanSelector } from "./SelectPlanSelector";
import {
  ProductName,
  ProductInterval,
  ProductStatus,
  IProductObject,
  IPlanObject,
} from "@/api";
import {
  CancelOptionsModal,
  ConfirmCancelModal,
  ConfirmPlanModal,
} from "@/components";
import { ROUTES, SELECT_PLAN_SLUGS } from "@/config";
import { DataHelper } from "@/helpers";
import {
  getIsProPlan,
  getIsFreePlan,
  findDefaultFreePlanInPlans,
} from "@/helpers/plan";
import { getLivePlans } from "@/helpers/plan";
import { useCreateOrUpdateProduct, useQueryParams } from "@/hooks";
import { useAccountContext, useModalDispatch } from "@/providers";
import { SelectCMSPlans } from "@/types";
import { capitalize, numberToCurrency } from "@/utils";
import { Button, ModalActionType } from "@narrative-software/narrative-web-ui";
import moment from "moment";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect } from "react";

const SELECT_PLAN_CARD_PROPS_COMMON = {
  planName: ProductName.Select,
  description:
    "Select images fast with the support of trailblazing AI technology which detects faces, focus, and blinking.",
  iconFillClassName: "fill-gengar",
};

type Props = {
  plans: IPlanObject[];
  cmsPlans: SelectCMSPlans[];
  existingProduct?: IProductObject;
};

const PlanCardSelect: React.FC<Props> = ({
  plans,
  cmsPlans,
  existingProduct,
}) => {
  const router = useRouter();
  const modalDispatch = useModalDispatch();
  const { accountID } = useAccountContext();
  const [
    doCreateOrUpdateProduct,
    { isLoading: isSendingCreateOrUpdateProduct },
  ] = useCreateOrUpdateProduct();
  const queryParams = useQueryParams();

  const freePlan = findDefaultFreePlanInPlans(plans)!;
  const livePlans = getLivePlans(plans); // Remove grandfathered plans for use in the upgrade/subscribe modal

  const plansWithPriceData = mergeCMSPlanWithStripePlan(
    cmsPlans,
    livePlans
  ).sort((a) =>
    SELECT_PLAN_SLUGS.allFree.includes(a.stripePlanSlug) ? -1 : 1
  );

  const existingPlan =
    existingProduct &&
    DataHelper.getPlanById(existingProduct.relationships.plan.data.id, plans);
  const isProductStatusCanceled = getIsProductCancelled(existingProduct);

  const isProPlan = !isProductStatusCanceled && getIsProPlan(existingPlan);
  const isFreePlan = !isProductStatusCanceled && getIsFreePlan(existingPlan);

  // Handle plan click
  const onPlanSelect = async ({
    planID,
    interval,
  }: {
    planID: string;
    interval: ProductInterval;
  }) => {
    try {
      // If a user clicked Select Free
      if (planID === freePlan.id) {
        if (isProPlan && existingProduct) {
          modalDispatch({
            type: ModalActionType.SetContent,
            payload: {
              children: (
                <ConfirmPlanModal
                  product={existingProduct}
                  parkingPlanID={freePlan.id}
                />
              ),
            },
          });
        } else {
          await doCreateOrUpdateProduct({
            accountID,
            planID,
            interval,
            product: ProductName.Select,
            productID: existingProduct?.id,
          });
          modalDispatch({ type: ModalActionType.Close });
        }
      } else {
        await router.push({
          pathname: `/${ROUTES.CHECKOUT.SLUG}`,
          query: { planID, interval },
        });
        modalDispatch({ type: ModalActionType.Close });
      }
    } catch {}
  };

  // Open the plan selector in a modal
  const openPlanModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        size: "large",
        hideBorder: true,
        showOverflow: true,
        children: (
          <SelectPlanSelector
            plans={plansWithPriceData}
            mode={isProPlan ? "downgrade" : isFreePlan ? "upgrade" : "normal"}
            onSelect={onPlanSelect}
            coupon={existingProduct?.attributes.coupon ?? undefined}
          />
        ),
      },
    });
  };

  // Open select plan modal on load if query param is set
  useEffect(() => {
    if (queryParams["change-select-plan"]) {
      openPlanModal();
    }
  }, [queryParams["change-select-plan"]]);

  // Get unsubscribed plan card
  const getUnsubscribedPlanCard = () => {
    const handleClick = async () => {
      try {
        await doCreateOrUpdateProduct({
          accountID,
          planID: freePlan.id,
          interval: ProductInterval.Month,
          product: ProductName.Select,
          productID: existingProduct?.id,
        });
        if (existingProduct) return;
        await router.push(`/${ROUTES.SELECT.DOWNLOAD.SLUG}`);
      } catch {}
    };

    let buttonPrimary = (
      <Button
        colour="gengar"
        appearance="primary"
        onClick={handleClick}
        isLoading={isSendingCreateOrUpdateProduct}
        showLoader
      >
        Try Select free
      </Button>
    );

    return (
      <PlanCard
        {...SELECT_PLAN_CARD_PROPS_COMMON}
        buttonPrimary={buttonPrimary}
        image={
          <div className="mt-1.5">
            <Image
              src="/images/narrative-select@2x.webp"
              alt="Narrative"
              width="230"
              height="152.5"
            />
          </div>
        }
      />
    );
  };

  /**
   * Unsubscribed
   */
  if (!existingPlan || !existingProduct || isProductStatusCanceled) {
    return getUnsubscribedPlanCard();
  }

  // Open the cancel options modal
  const openOptionsModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <CancelOptionsModal
            product={existingProduct}
            plan={existingPlan}
            parkingPlanID={freePlan.id}
          />
        ),
      },
    });
  };

  // Open the confirm cancel modal
  const openCancelModal = () => {
    modalDispatch({
      type: ModalActionType.SetContent,
      payload: {
        children: (
          <ConfirmCancelModal
            product={existingProduct}
            plan={existingPlan}
            parkingPlanID={isFreePlan ? undefined : freePlan.id}
          />
        ),
      },
    });
  };

  // Values for plan card
  const {
    attributes: { name },
  } = existingPlan;

  const {
    attributes: { interval },
  } = existingProduct;

  const planName = name.replace("Select", "Narrative"); //TODO: Remove when data has been appropriately replaced in services
  const coupon = existingProduct.attributes.coupon?.name || "-";
  const discount = existingProduct.attributes.coupon?.["percent-off"]
    ? `${existingProduct.attributes.coupon["percent-off"]}% off`
    : "-";
  const status =
    existingProduct.attributes.status === ProductStatus.IncompleteExpired
      ? "Payment method expired"
      : capitalize(existingProduct.attributes.status).replace(/_/g, " ");

  const couponAndDiscountItems = (
    <>
      <PlanCard.Item term="Coupon">{coupon}</PlanCard.Item>
      <PlanCard.Item term="Discounts & rewards">{discount}</PlanCard.Item>
    </>
  );

  /**
   * Conditionally render by plan type
   */
  switch (true) {
    /**
     * Free
     */
    case isFreePlan: {
      const buttonPrimary = (
        <Button colour="gengar" appearance="primary" onClick={openPlanModal}>
          Upgrade to Pro
        </Button>
      );
      const buttonSecondary = (
        <button type="button" className="underline" onClick={openCancelModal}>
          Cancel plan
        </button>
      );
      return (
        <PlanCard
          {...SELECT_PLAN_CARD_PROPS_COMMON}
          buttonPrimary={buttonPrimary}
          buttonSecondary={buttonSecondary}
        >
          <PlanCard.Item term="Your plan">{planName}</PlanCard.Item>
          <PlanCard.Item term="Price">FREE</PlanCard.Item>
          <PlanCard.Item term="Subscription status">{status}</PlanCard.Item>
          <PlanCard.Item term="Next payment">-</PlanCard.Item>
          {couponAndDiscountItems}
        </PlanCard>
      );
    }

    /**
     * Pro
     */
    case isProPlan: {
      const index = `${interval}ly-price` as "yearly-price" | "monthly-price";
      const price = `${numberToCurrency(
        existingPlan.attributes[index]
      )} USD/${interval}`;
      const nextPayment = moment
        .unix(existingProduct.attributes["current-period-end"])
        .format("D MMM YYYY");

      const buttonPrimary = (
        <Button
          colour="gray-600"
          appearance="secondary"
          onClick={openPlanModal}
        >
          Change plan
        </Button>
      );

      const buttonSecondary = (
        <button type="button" className="underline" onClick={openOptionsModal}>
          Cancel plan
        </button>
      );

      return (
        <PlanCard
          {...SELECT_PLAN_CARD_PROPS_COMMON}
          buttonPrimary={buttonPrimary}
          buttonSecondary={buttonSecondary}
        >
          <PlanCard.Item term="Your plan">{planName}</PlanCard.Item>
          <PlanCard.Item term="Price">{price}</PlanCard.Item>
          <PlanCard.Item term="Subscription status">{status}</PlanCard.Item>
          <PlanCard.Item term="Next payment">{nextPayment}</PlanCard.Item>
          {couponAndDiscountItems}
        </PlanCard>
      );
    }

    /**
     * Unsubscribed
     */
    default: {
      return getUnsubscribedPlanCard();
    }
  }
};

export default PlanCardSelect;
