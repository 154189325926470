import { RewardType } from "@/api";
import { publishEvent, TOPICS } from "@/events";
import EmailIcon from "@/svg/email.svg";
import FacebookIcon from "@/svg/facebook.svg";
import MessengerIcon from "@/svg/messenger.svg";
import WhatsAppIcon from "@/svg/whatsapp.svg";
import { Product } from "@/types";
import { AnchorButton } from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import Image from "next/image";

type Props = {
  href: string;
  title: string;
  color: string;
  external?: boolean;
  className?: string;
  shareType: "facebook" | "messenger" | "whatsapp" | "email";
  type: RewardType;
  code: string;
  product?: Product;
};

const SocialLink: React.FC<Props> = ({
  href,
  title,
  color,
  external,
  className,
  children,
  shareType,
  type,
  code,
  product,
}) => (
  <div className={classNames("w-13.75 h-13.75", className)}>
    <AnchorButton
      href={href}
      external={external}
      title={title}
      style={{
        border: "none",
        backgroundColor: color,
        paddingLeft: "0",
        paddingRight: "0",
      }}
      onClick={() => {
        publishEvent(TOPICS.referralShare, {
          action: "click",
          product: product,
          type: type,
          referral_code: code,
          invite_option_interacted: shareType,
        });
      }}
    >
      {children}
    </AnchorButton>
  </div>
);

type SocialLinksProps = {
  urlToShare: string;
  code: string;
  product?: Product;
  type: RewardType;
};

const SocialLinks: React.FC<SocialLinksProps> = ({
  urlToShare,
  code,
  type,
  product,
}) => (
  <div className="flex space-x-3 mt-3 md:mt-0 md:ml-3">
    <SocialLink
      href={`https://www.facebook.com/sharer.php?u=${urlToShare}`}
      title="Share on Facebook"
      color="#4968ad"
      shareType="facebook"
      code={code}
      type={type}
      product={product}
      external
    >
      <Image src={FacebookIcon} alt="Share on Facebook" unoptimized priority />
    </SocialLink>
    <SocialLink
      href={`fb-messenger://share?link=${urlToShare}`}
      title="Share on Messenger"
      color="#fbfbfb"
      className="lg:hidden"
      shareType="messenger"
      code={code}
      type={type}
      product={product}
    >
      <Image
        src={MessengerIcon}
        alt="Share on Messenger"
        unoptimized
        priority
      />
    </SocialLink>
    <SocialLink
      href={`https://api.whatsapp.com/send?text=${urlToShare}`}
      title="Share on WhatsApp"
      color="#25d366"
      shareType="whatsapp"
      code={code}
      type={type}
      product={product}
      external
    >
      <Image src={WhatsAppIcon} alt="Share on WhatsApp" unoptimized priority />
    </SocialLink>
    {/* <SocialLink
      href={`mailto:?body=${urlToShare}`}
      title="Share via Email"
      color="#bdbdbd"
      shareType="email"
      code={code}
      type={type}
      product={product}
      external
    >
      <Image src={EmailIcon} alt="Share via Email" unoptimized priority />
    </SocialLink> */}
  </div>
);

export default SocialLinks;
