import { ProductName, IRewardObject } from "@/api";
import { Referral } from "@/containers";
import classNames from "classnames";
import moment from "moment";

const requiredSignUps = 4;

type Props = {
  product: ProductName;
  rewards?: IRewardObject[];
  className?: string;
};

const ReferralSignUps: React.FC<Props> = ({ product, rewards, className }) => {
  if (!rewards) return null;
  
  const productTitle = product.replace("Select", "Narrative"); //TODO: Remove when data has been appropriately replaced in services

  const rewardsCount = rewards.length;
  const isComplete = rewardsCount >= requiredSignUps;

  return (
    <section
      className={classNames("p-8 lg:px-16 lg:py-14", className, {
        "bg-gengar": product === ProductName.Select,
        "bg-slowpoke": product === ProductName.Publish,
      })}
    >
      <h3 className="text-h3 font-semibold text-white leading-tight mb-4">
        Your {productTitle} referral sign ups
      </h3>
      <p className="text-white">
        {isComplete
          ? `${rewardsCount} friends signed up to ${productTitle}! Enjoy your year of free ${productTitle} Pro access.`
          : `${rewardsCount} ${
              rewardsCount === 1 ? "friend has" : "friends have"
            } signed up to ${productTitle}. Keep sharing to get a year of ${productTitle} Pro for free!`}
      </p>
      <div className="grid grid-cols-1 gap-3 mt-8 md:grid-cols-4 md:gap-7.5 lg:gap-4 xl:gap-7.5">
        {Array.from({ length: requiredSignUps }).map((_, index) => {
          const reward = rewards[index];
          const isActive = Boolean(reward);
          const signUpDate =
            reward && moment(reward["issue-date"]).format("DD MMMM YYYY");
          return (
            <Referral
              key={index}
              name={`Referral ${index + 1}`}
              {...{ index, product, signUpDate, isActive, isComplete }}
            />
          );
        })}
      </div>
    </section>
  );
};

export default ReferralSignUps;
