import {
  useEditCreditPacksQuery,
  useEditCreditBalanceQuery,
  useEditCreditPackSingleLookupQuery,
} from "../../api/hooks";
import {
  usePlansQuery,
  useGrandFatheredPlansQuery,
  useProductsQuery,
  ProductName,
  useAccountQuery,
} from "@/api";
import { PlanCardSelect, PlanCardPublish } from "@/components";
import { PlanCardEdit } from "@/components/Subscriptions/PlanCardEdit";
import { DataHelper } from "@/helpers";
import { useQueryParams } from "@/hooks";
import { useAccountContext } from "@/providers";
import { SelectCMSPlans } from "@/types";
import { Spinner } from "@narrative-software/narrative-web-ui";

type PlansProps = {
  cmsPlans: SelectCMSPlans[];
};



export const Plans = ({ cmsPlans }: PlansProps) => {
  const { accountID } = useAccountContext();
  const queryParams = useQueryParams<{ packId: Uuid }>(); 
  const { data: plansData, isFetching: isFetchingPlans } = usePlansQuery();
  const {
    data: grandfatheredPlansData,
    isFetching: isFetchingGrandfatheredPlans,
  } = useGrandFatheredPlansQuery();

  const { data: productsData, isFetching: isFetchingProducts } =
    useProductsQuery();
  const { data: creditPacks, isFetching: isFetchingPacks } =
    useEditCreditPacksQuery();
  const { data: accountData, isFetching: isFetchingAccount } =
    useAccountQuery();
  const {
    data: { balance: editCreditsBalance } = {},
    isLoading: isFetchingCreditsBalance,
  } = useEditCreditBalanceQuery();
  const preferredPackId =
    queryParams.packId ||
    accountData?.data.attributes.preferences["default-pack-id"];


  // Loading state
  if (
    !accountID ||
    !plansData ||
    !grandfatheredPlansData ||
    !productsData ||
    !creditPacks ||
    !accountData ||
    editCreditsBalance === undefined ||
    isFetchingCreditsBalance ||
    isFetchingPlans ||
    isFetchingGrandfatheredPlans ||
    isFetchingProducts ||
    isFetchingPacks ||
    isFetchingAccount
  ) {
    return <Spinner />;
  }

  // Select plans including grandfathered ones so we can show old pro users their current plan
  const selectPlans = [
    ...plansData.data,
    ...grandfatheredPlansData.data,
  ].filter((plan) => plan.attributes["product-name"] === ProductName.Select);
  const existingSelectProduct = DataHelper.getProductByName(
    ProductName.Select,
    productsData.data
  );

  // Publish
  const publishPlans = plansData.data.filter(
    (plan) => plan.attributes["product-name"] === ProductName.Publish
  );
  const existingPublishProduct = DataHelper.getProductByName(
    ProductName.Publish,
    productsData.data
  );

  return (
    <div
      className="grid gap-5 xl:gap-10 max-w-[1620px]"
      style={{
        gridTemplateColumns: "repeat(auto-fit, minmax(340px, 1fr))",
      }}
    >
      <PlanCardSelect
        plans={selectPlans}
        cmsPlans={cmsPlans}
        existingProduct={existingSelectProduct}
      />
      <PlanCardEdit
        plans={selectPlans}
        creditPacks={creditPacks}
        preferredPackId={preferredPackId || null}
        existingProduct={existingSelectProduct}
        editCreditsBalance={editCreditsBalance}
      />
      <PlanCardPublish
        plans={publishPlans}
        existingProduct={existingPublishProduct}
      />
    </div>
  );
};

export default Plans;
