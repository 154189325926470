import { EXTERNAL } from "@/config";
import { ConfirmActionForm } from "@/containers";
import { useModalDispatch } from "@/providers";
import { ModalActionType } from "@narrative-software/narrative-web-ui";

const confirmationString = "DELETE";

type Props = {
  onSubmit(): Promise<void>;
};

const DeleteModal: React.FC<Props> = ({ onSubmit }) => {
  const modalDispatch = useModalDispatch();

  // Handle back click
  const handleBackClick = () => {
    modalDispatch({ type: ModalActionType.Close });
  };

  return (
    <>
      <h3 className="mb-6 text-h3 font-semibold leading-tight">
        Are you sure you want to delete your account?
      </h3>
      <p className="mb-4">
        We’re sad to see you go. If there’s any way to convince you to stay or
        if you have any feedback about how we can do better, please contact our{" "}
        <a
          href={EXTERNAL.SUPPORT.HREF}
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:no-underline"
        >
          support team
        </a>
        .
      </p>
      <ConfirmActionForm
        {...{ confirmationString, onSubmit }}
        onBackClick={handleBackClick}
      />
    </>
  );
};

export default DeleteModal;
