import {
  useDeleteProductMutation,
  ProductName,
  IProductObject,
  IPlanObject,
} from "@/api";
import { CancelOptionsModal } from "@/components";
import { ConfirmActionForm } from "@/containers";
import {
  useAccountContext,
  useAlertDispatch,
  useModalDispatch,
} from "@/providers";
import {
  AlertActionType,
  ModalActionType,
} from "@narrative-software/narrative-web-ui";

type ConfirmCancelModalProps = {
  plan: IPlanObject;
  product: IProductObject;
  parkingPlanID?: string;
};

const ConfirmCancelModal = ({
  product,
  plan,
  parkingPlanID,
}: ConfirmCancelModalProps) => {
  const alertDispatch = useAlertDispatch();
  const modalDispatch = useModalDispatch();
  const { accountID } = useAccountContext();
  const { mutateAsync: doDeleteProduct } = useDeleteProductMutation();

  const productID = product.id;
  const productName = product.attributes.name;
  const planName = plan.attributes.name.replace("Select", "Narrative"); //TODO: Remove when data has been appropriately replaced in services

  const textMap = {
    [ProductName.Select]: `You are about to cancel your ${planName} subscription.`,
    [ProductName.Publish]: `You are about to cancel your ${planName} subscription. By doing so you will schedule your Publish account for deletion, this will take all of your Publish posts offline after 30 days. You’ll have 30 days to reactivate or download content.`,
    [ProductName.Save]: "",
  };

  const handleBackClick = () => {
    if (parkingPlanID) {
      modalDispatch({
        type: ModalActionType.SetContent,
        payload: {
          children: (
            <CancelOptionsModal {...{ product, plan, parkingPlanID }} />
          ),
        },
      });
    } else {
      modalDispatch({ type: ModalActionType.Close });
    }
  };

  const handleSubmit = async () => {
    await doDeleteProduct({ accountID, productID });
    modalDispatch({ type: ModalActionType.Close });
    alertDispatch({
      type: AlertActionType.SetContent,
      payload: {
        type: "success",
        title: `${planName} subscription cancelled`,
      },
    });
  };

  return (
    <>
      <h3 className="mb-6 text-h3 font-semibold leading-tight">
        Are you sure you want to cancel {planName}?
      </h3>
      <p className="mb-4">{textMap[productName]}</p>
      <ConfirmActionForm
        confirmationString="CANCEL"
        onBackClick={handleBackClick}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default ConfirmCancelModal;
