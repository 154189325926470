import { ProductName } from "@/api";
import {
  PasswordField,
  OperatingSystemField,
  UserOSAnswer,
  SelectField,
  Terms,
  GoogleButton,
  Or,
} from "@/components";
import { IS_GOOGLE_AUTH_ENABLED } from "@/config";
import { Steps } from "@/containers";
import { publishEvent, TOPICS } from "@/events";
import { ActiveCampaignHelper } from "@/helpers";
import { capitalize, productLowerCase } from "@/utils";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Button, ButtonColour } from "@narrative-software/narrative-web-ui";
import classNames from "classnames";
import { Formik, Form, FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";

const emailLeadHiddenInputs = {
  select: {
    u: "16",
    f: "16",
    s: "",
    c: "0",
    m: "0",
    act: "sub",
    v: "2",
  },
  publish: {
    u: "20",
    f: "20",
    s: "",
    c: "0",
    m: "0",
    act: "sub",
    v: "2",
  },
};

const blogOptions = ["", "yes", "no"] as const;
const genreOptions = [
  "",
  "Weddings, engagements and/or elopements",
  "Families",
  "Maternity",
  "Newborns",
  "Boudoir",
  "Portraits",
  "Events",
  "Sports",
  "Commercial",
  "I’m not a professional photographer",
  "Other",
] as const;

const signupSourcesOptions = [
  "Ad on Facebook or Instagram",
  "Ad on Google",
  "Event or workshop",
  "Narrative’s social media",
  "Someone else’s social media",
  "A Facebook group",
  "YouTube",
  "Blog or website",
  "Referral from a friend",
  "Podcast",
  "I can’t remember",
  "Other",
];

export const genreFieldOptions = genreOptions.map((option) => ({
  value: option,
  label: option,
}));
export const signupSourcesFieldOptions = signupSourcesOptions.map((option) => ({
  value: option,
  label: option,
}));
export const blogFieldOptions = blogOptions.map((option) => ({
  value: option,
  label: capitalize(option),
}));

export type GenreOptions = typeof genreOptions[number];
export type BlogOptions = typeof blogOptions[number];

export interface ISignUpFormTrial {
  email: string;
  firstName: string;
  password: string;
  genre: GenreOptions;
  genreOther: string;
  hasBlog: BlogOptions;
  userOSAnswer: UserOSAnswer;
  signupSources: string[];
  signupSourceOther: string;
}

type Props = {
  product: ProductName;
  step: Steps;
  setStep: (step: Steps, callback?: () => void) => void;
  onSubmit: (params: ISignUpFormTrial) => Promise<void>;
  onGoogleClick: () => void;
  email?: string;
  firstName?: string;
  colour?: ButtonColour;
  buttonLabel?: string;
  isGoogleSignUp?: boolean;
};

const SignUpFormTrial: React.FC<Props> = ({
  product,
  step,
  setStep,
  onSubmit,
  onGoogleClick,
  isGoogleSignUp,
  email = "",
  firstName = "",
  colour = "black",
  buttonLabel = "Create account",
}) => {
  const isPublish = product === "Publish";
  const app = productLowerCase(product);

  // Initial values
  const initialValues: ISignUpFormTrial = {
    email,
    firstName,
    password: "",
    genre: "",
    genreOther: "",
    hasBlog: "",
    userOSAnswer: "",
    signupSources: [],
    signupSourceOther: "",
  };

  // Validation
  const validationSchema = Yup.object().shape({
    email: isGoogleSignUp
      ? Yup.string().optional()
      : Yup.string().email("Invalid email").required("Email required"),
    firstName: Yup.string().required("First name required"),
    password: isGoogleSignUp
      ? Yup.string().optional()
      : Yup.string()
          .min(8, "Password must be at least 8 characters")
          .max(100, "Password must be no more than 100 characters")
          .required("Password required"),
    genre: Yup.string().required("Photography genre required"),
    genreOther: Yup.string().optional(),
    hasBlog: isPublish
      ? Yup.string().required("Field required")
      : Yup.string().optional(),
    userOSAnswer: !isPublish
      ? Yup.string().optional()
      : Yup.string().required("Computer type required"),
    signupSources: Yup.array()
      .min(1, "At least one signup source is required")
      .required("Signup source required"),
  });

  // Handle submit
  const handleSubmit = async (
    values: ISignUpFormTrial,
    actions: FormikHelpers<ISignUpFormTrial>
  ) => {
    try {
      await onSubmit(values);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        validateForm,
        setFieldTouched,
        setErrors,
        setTouched,
      }) => {
        const { email, genre } = values;
        const showGenreOther = values.genre === "Other";
        const showSignupSourceOther = values.signupSources.includes("Other");

        const onContinueClick = async () => {
          const { email: emailError } = await validateForm();
          if (emailError) {
            setFieldTouched("email", true, true);
          } else {
            setStep(2);
            // quick hack solution: want to tie the button of step 1 to return keypress form submission, but then the
            // form actually submits after this callback is run and sets form errors on the second step. So by
            // next-ticking the reset, it all looks fine to the user. Most certainly a cleaner way for someone with more
            // time :)
            setTimeout(() => {
              setErrors({});
              setTouched({});
            }, 0);
            publishEvent(TOPICS.emailLead, { app, email });
            publishEvent(TOPICS.signUpStarted, {
              app,
              sign_up_method: "email",
            });
            ActiveCampaignHelper.sendForm({
              ...emailLeadHiddenInputs[app],
              email,
            });
          }
        };

        const getFieldProps = (
          name: keyof ISignUpFormTrial,
          label: string,
          helperText?: string
        ) => {
          const id = `sign-up-trial-${name}-input`;
          return {
            id,
            name,
            label,
            type: "text",
            value: values[name],
            error: touched[name] && !!errors[name],
            helperText: (touched[name] && errors[name]) || helperText,
            onChange: handleChange,
            onBlur: handleBlur,
            fullWidth: true,
            inputProps: { "data-testid": id },
          };
        };

        return (
          <Form noValidate>
            {/* Step 1 */}
            <div
              className={classNames({
                hidden: step !== 1 && IS_GOOGLE_AUTH_ENABLED,
              })}
            >
              <div className="mb-7">
                <TextField {...getFieldProps("email", "Email")} type="email" />
              </div>

              {IS_GOOGLE_AUTH_ENABLED && (
                <>
                  <Button
                    colour={colour}
                    onClick={onContinueClick}
                    type={
                      step === 1 && IS_GOOGLE_AUTH_ENABLED ? "submit" : "button"
                    }
                    data-testId="continue-with-email-button"
                  >
                    Continue with email
                  </Button>
                  <Or />
                  <GoogleButton onClick={onGoogleClick}>
                    Continue with Google
                  </GoogleButton>
                  <div className="mt-5">
                    <Terms className="text-center" />
                  </div>
                </>
              )}
            </div>

            {/* Step 2 */}
            <div
              className={classNames({
                hidden: step !== 2 && IS_GOOGLE_AUTH_ENABLED,
              })}
            >
              <div className="mb-5">
                <TextField
                  {...getFieldProps("firstName", "First name")}
                  type="text"
                />
              </div>
              <div className={classNames("mb-5", { hidden: isGoogleSignUp })}>
                <PasswordField
                  {...getFieldProps(
                    "password",
                    "Password",
                    "Password must be at least 8 characters"
                  )}
                  showVisibilityToggle
                />
              </div>

              <SelectField
                {...getFieldProps(
                  "genre",
                  product === "Select"
                    ? "What is your primary professional shoot type?"
                    : "What is your primary professional post type?"
                )}
                options={genreFieldOptions}
                onChange={(e) => {
                  handleChange(e);
                  publishEvent(TOPICS.signUpGenre, {
                    app,
                    genre: e.target.value,
                  });
                }}
              />

              {showGenreOther && (
                <div className="mt-5">
                  <TextField
                    {...getFieldProps(
                      "genreOther",
                      product === "Select"
                        ? "Describe your ‘other’ type of shoot (optional)"
                        : "Describe your ‘other’ type of post (optional)"
                    )}
                    fullWidth
                  />
                </div>
              )}

              <div className="mt-7">
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="sign-up-trial-signupSources-input"
                    error={Boolean(
                      touched.signupSources && errors.signupSources
                    )}
                  >
                    Where did you hear about Narrative?
                  </InputLabel>
                  <Select
                    multiple
                    fullWidth
                    name="signupSources"
                    inputProps={{
                      id: "sign-up-trial-signupSources-input",
                      style: { zIndex: -1 }, // ensures the select is clickable in every scenario.
                    }}
                    error={Boolean(
                      touched.signupSources && errors.signupSources
                    )}
                    value={values.signupSources}
                    onChange={(e) => {
                      handleChange(e);
                      publishEvent(TOPICS.signUpSource, {
                        personal_sign_up_source: e.target.value,
                        app: productLowerCase,
                      });
                    }}
                    renderValue={(selected: any) => (
                      <span style={{ textOverflow: "ellipsis" }}>
                        {selected.join(", ")}
                      </span>
                    )}
                    MenuProps={{
                      getContentAnchorEl: null, // prevents auto-adjustment of menu position
                      PaperProps: { style: { marginTop: 40 } },
                    }}
                    data-testid="sign-up-charge-signupSources-input"
                  >
                    {signupSourcesFieldOptions.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        <Checkbox
                          color="primary"
                          checked={Boolean(
                            values &&
                              values.signupSources &&
                              values.signupSources.indexOf(value) > -1
                          )}
                        />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.signupSources && Boolean(errors.signupSources) && (
                    <FormHelperText error>
                      {errors.signupSources}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              {showSignupSourceOther && (
                <div className={classNames("mt-5")}>
                  <TextField
                    {...getFieldProps(
                      "signupSourceOther",
                      "Describe the 'other' place (optional)"
                    )}
                    fullWidth
                  />
                </div>
              )}

              {isPublish && (
                <div className="mt-5">
                  <SelectField
                    {...getFieldProps(
                      "hasBlog",
                      "Do you currently have a website?"
                    )}
                    options={blogFieldOptions}
                  />
                </div>
              )}

              <div className={classNames("mt-5", { hidden: !isPublish })}>
                <OperatingSystemField
                  {...getFieldProps(
                    "userOSAnswer",
                    "What computer(s) do you use?"
                  )}
                  onChange={(e) => {
                    handleChange(e);
                    publishEvent(TOPICS.signUpOs, { app, os: e.target.value });
                  }}
                />
              </div>

              <div className="mt-8 sm:mt-12">
                <Button
                  type={
                    step === 2 || !IS_GOOGLE_AUTH_ENABLED ? "submit" : "button"
                  }
                  colour={colour}
                  isLoading={isSubmitting}
                  showLoader
                  testId="sign-up-trial-submit-button"
                >
                  {buttonLabel}
                </Button>
              </div>

              {!IS_GOOGLE_AUTH_ENABLED && (
                <div className="mt-5">
                  <Terms className="text-center" />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignUpFormTrial;
