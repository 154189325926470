import MalePhotographer from "../../../public/svg/male-photographer.svg";
import Photographer from "../../../public/svg/photographer.svg";
import {
  ProductName,
  ProductInterval,
  ErrorCode,
  useLoginMutation,
  IGoogleOAuthResponse,
} from "@/api";
import { ROUTES } from "@/config";
import {
  StepWrapper,
  SignUpFormTrial,
  LoginForm,
  ISignUpFormTrial,
} from "@/containers";
import { TOPICS, publishEvent } from "@/events";
import { AuthHelper, CouponHelper, TrackingHelper } from "@/helpers";
import {
  useSignUp,
  useLoginSuccess,
  useAuthRedirect,
  useGoogleSignUp,
  useQueryParams,
} from "@/hooks";
import {
  useAuthContext,
  handleErrorResponse,
  useAlertDispatch,
} from "@/providers";
import { browserDetection, productLowerCase } from "@/utils";
import { Button, ButtonColour } from "@narrative-software/narrative-web-ui";
import { AxiosError } from "axios";
import Image from "next/image";
import { useCallback, useState } from "react";

const { isMacOS } = browserDetection;

export type Steps = 1 | 2 | 3 | 4;

// Step 1 and 2 use the same component
const getStep = (step: Steps) => {
  return (step === 1 ? 0 : step - 2) as 0 | 1 | 2;
};

type Props = {
  planID: string;
  interval: ProductInterval;
  product: ProductName;
  coupon?: string;
  onShowNav?: () => void;
};

const SignUpFlow: React.FC<Props> = ({
  planID,
  interval,
  product,
  coupon,
  onShowNav,
}) => {
  const [step, setStep] = useState<Steps>(1);
  const [visible, setVisible] = useState<Nullable<number>>(1);
  const [email, setEmail] = useState<string>("");

  const queryParams = useQueryParams();
  const alertDispatch = useAlertDispatch();
  const doAuthRedirect = useAuthRedirect();
  const { setAuthenticated } = useAuthContext();

  // Custom error handler, we don’t want to show an alert on 401 (auth details incorrect) or 422 (existing email address)
  const onError = (error: AxiosError) => {
    const status = error.response?.status;
    if (status === 401 || status === 422) return;
    handleErrorResponse(error, alertDispatch, setAuthenticated);
  };

  const [doSignUp] = useSignUp({ config: { onError }, sendFailedEvent: false });
  const [doLoginSuccess] = useLoginSuccess();
  const { mutateAsync: doLogin } = useLoginMutation({ onError });

  // Move user to next step after Google OAuth
  const onGoogleSuccess = useCallback((data: IGoogleOAuthResponse) => {
    if (data.oauth_status === "user_exists") {
      transitionToStep(4);
    } else {
      transitionToStep(2);
    }
  }, []);

  const { openWindow: openGoogleWindow, data: googleData } = useGoogleSignUp(
    onGoogleSuccess,
    false
  );

  const productName = product === "Select" ? "Narrative" : product;

  const colour =
    productName === "Narrative" ? "stealth-bomber" : "slowpoke";

  // Transition to step
  const transitionToStep = useCallback((step: Steps, callback?: () => void) => {
    let ms = 0;
    if (step !== 2) {
      ms = 500;
      setVisible(null);
    }
    // Wait for StepWrapper CSS transition to end (TODO: use onAfterLeave prop on StepWrapper > Transition component)
    setTimeout(() => {
      setStep(step);
      setVisible(step);
      callback?.();
    }, ms);
  }, []);

  // Google button click
  const onGoogleClick = () => {
    openGoogleWindow(productLowerCase(product));
    publishEvent(TOPICS.signUpStarted, {
      app: product.toLowerCase(),
      sign_up_method: "google",
    });
  };

  // Download click
  const onDownloadClick = async () => {
    const route =
      product === "Select"
        ? `/${ROUTES.SELECT.DOWNLOAD.SLUG}`
        : `/${ROUTES.PUBLISH.DOWNLOAD.SLUG}`;
    await doAuthRedirect(route);
  };

  // Login success
  const onLoginSuccess = async (
    formValues: ISignUpFormTrial,
    userId?: string
  ) => {
    try {
      const isExistingProduct = await doLoginSuccess({
        planID,
        interval,
        product,
      });
      if (isExistingProduct) {
        transitionToStep(4, onShowNav);
      } else {
        TrackingHelper.sendSignUpSucceededEvent({
          ...formValues,
          userId,
          product,
          coupon,
          method: "email",
        });
      }
      CouponHelper.deleteCookie();
    } catch {}
  };

  // Sign up with email submit
  const onSignUpSubmit = async (formValues: ISignUpFormTrial) => {
    try {
      await doSignUp({ planID, interval, product, googleData, formValues });
    } catch (e: any) {
      // If email is taken, attempt login
      if (!googleData && e.message === ErrorCode.UserExisting) {
        try {
          const { access_token } = await doLogin(formValues);
          const userId = AuthHelper.getUserIdFromJwt(access_token);
          await onLoginSuccess(formValues, userId);
        } catch (e: any) {
          // If login fails, go to login form (with just password field)
          const status = e.response?.status;
          const reason = status ? `status_code_${status}` : e.message;
          publishEvent(TOPICS.signUpFailed, { reason });
          setEmail(formValues.email);
          transitionToStep(3);
        }
      } else {
        throw e;
      }
    }
  };

  // Steps
  return [
    // Step 1 and 2 - Email and password
    <StepWrapper
      key="sign-up"
      isVisible={visible === 1 || visible === 2}
      title={<>Get started free on {productName}</>}
      subheading={
        <>
          Download {productName} for free
          {product === "Select" ? "" : " on macOS"}.{" "}
          <br className="md:hidden" />
          <strong className="font-semibold">No credit card required</strong>.
        </>
      }
      form={
        <SignUpFormTrial
          {...{ planID, interval, product, step }}
          colour={colour as ButtonColour}
          buttonLabel={
            isMacOS && product === "Publish" ? "Download free now" : "Continue"
          }
          email={queryParams.email}
          setStep={transitionToStep}
          onSubmit={onSignUpSubmit}
          onGoogleClick={onGoogleClick}
          isGoogleSignUp={!!googleData}
          firstName={googleData?.f_name}
        />
      }
    >
      <div
        className="absolute top-0 right-1/2 mt-12 mr-80"
        style={{ width: 379, height: 451 }}
      >
        <Image src={Photographer} alt="" width="379" height="451" priority />
      </div>
    </StepWrapper>,

    // Step 3 - Login
    <StepWrapper
      key="login"
      isVisible={visible === 3}
      title="Continue with your account"
      subheading={
        <>
          <strong className="font-semibold">{email}</strong> is already
          associated to a Narrative account. Enter your password to sign in and
          download.
        </>
      }
      form={
        <LoginForm
          {...{ email }}
          colour={colour as ButtonColour}
          buttonLabel="Continue"
          onSuccess={onLoginSuccess}
          hideEmail
        >
          <div className="flex justify-center mt-6">
            <button
              onClick={() => transitionToStep(1)}
              className="text-xxs text-gray-700 underline"
            >
              Use a different email address
            </button>
          </div>
        </LoginForm>
      }
    />,

    // Step 4 - Download
    <StepWrapper
      key="download"
      isVisible={visible === 4}
      title={`You already have a ${product} account`}
      subheading={
        <>
          You can download {product} again, or{" "}
          <button onClick={() => setAuthenticated(true)} className="underline">
            click here
          </button>{" "}
          to see your account details.
        </>
      }
    >
      <Button
        colour={colour as ButtonColour}
        className="max-w-65"
        onClick={onDownloadClick}
      >
        Download {product} again
      </Button>
      <div className="mt-14">
        <Image src={MalePhotographer} alt="" width="274" height="337" />
      </div>
    </StepWrapper>,
  ][getStep(step)]; // Step 1 and 2 use the same component
};

export default SignUpFlow;
