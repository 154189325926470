import TextField from "@material-ui/core/TextField";
import { Button } from "@narrative-software/narrative-web-ui";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

export interface IConfirmActionFields {
  confirm: string;
}

const initialValues: IConfirmActionFields = {
  confirm: "",
};

type Props = {
  confirmationString: string;
  onBackClick(): void;
  onSubmit(): Promise<void>;
};

const ConfirmActionForm: React.FC<Props> = ({
  confirmationString,
  onSubmit,
  onBackClick,
}) => {
  const errorMessage = `You must type ‘${confirmationString}’ above`;

  const validationSchema = Yup.object().shape({
    confirm: Yup.mixed()
      .oneOf([confirmationString], errorMessage)
      .required(errorMessage),
  });

  const handleSubmit = async (
    values: IConfirmActionFields,
    actions: FormikHelpers<IConfirmActionFields>
  ) => {
    try {
      if (values.confirm === confirmationString) {
        await onSubmit();
        actions.resetForm({});
      }
    } catch {
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <p>
        Type {confirmationString} below, then click the ’Delete’ button. Please
        note, account deletion can take up to 30 days.
      </p>
      <div className="mt-8">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form noValidate autoComplete="off">
              <div className="mb-11">
                <TextField
                  name="confirm"
                  id="confirm-cancel-field"
                  label={`Type ‘${confirmationString}’ here`}
                  type="text"
                  value={values.confirm}
                  error={touched.confirm && !!errors.confirm}
                  helperText={touched.confirm && errors.confirm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </div>
              <div className="flex space-x-2.5 sm:max-w-80 sm:ml-auto">
                <Button
                  appearance="secondary"
                  colour="gray-600"
                  disabled={isSubmitting}
                  onClick={onBackClick}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  colour="magmar"
                  disabled={values.confirm !== confirmationString}
                  isLoading={isSubmitting}
                  showLoader
                >
                  Delete
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ConfirmActionForm;
